.node > label {
  cursor: pointer;
  margin-left: 2px;
}

.tag-list {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar {
    height: 0%;
  }
}

.tag-item {
  display: inline-block;
  margin: 4px;

  .search {
    width: 190px;
    border: none;
    border-bottom: solid 1px #ccc;
    outline: none;
  }
}

.tag-item:last-child {
  margin-right: 4px;
}
