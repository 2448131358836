@import '../../styles/PoultryWeights/_partial.scss';


.navigate {
  display: inline-block;
  margin: 0px 8px 0px 8px;
  font-size: 16px;
  color: #113252;
}

.subnavigate {
  display: inline-block;
  margin: 0px 5px 0px 5px;
  font-size: 16px;
  color: #417eba;
}

.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}

