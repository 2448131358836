@import '../../styles/PoultryWeights/partial';
.polygon-wrap {
  filter: drop-shadow(2px 2px 2px #00000029);
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;

  .inner-polygon {
    background: $secondary-color;
    color: #222;
    width: 70px;
    height: 60px;
    justify-content: center;
    display: flex;
    align-items: center;
    // padding: 2rem;
    font: bold 32px system-ui;
    clip-path: polygon(20% 0%, 80% 0%, 100% 50%, 80% 100%, 20% 100%, 0% 50%);
  }
}
