@import '../../../styles/Bpac/partial';

.map-input {
  position: absolute;
  top: 15px;
  z-index: 9999;
  border-radius: 6px;
  outline: none;
  border: 1px solid $primary-color;
}

.pac-container {
  z-index: 999999 !important;
}
