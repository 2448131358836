//global
$default-bold-text: 600;
$extra-bold: 700;

$default-font-weight: 400;

$default-light-gray: #f2f2f2;

$default-dark-gray: #7B7B7B;

$default-border-radius: 20px;

$default-input-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

$default-input-height: 36px;

$default-scrollbar-width: 12px;
