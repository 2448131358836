@import '../../../../styles/Bees/partial';

.app-bar-user {
  margin-top: $app-bar-height;
}

.app-bar-user-polygon {
  background-color: white;
  width: 70px;
  height: 60px;

  span {
    font-size: map-get($map: $typography-size, $key: 'lg');
    color: map-get($map: $typography-color, $key: 'primary');
  }
}
