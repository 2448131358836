@import '../../../../styles/Bees/partial';

.app-bar-notification-container {
  height: 60px;
  width: 63px;
  margin-top: $app-bar-height;
  position: relative;
  display: inline;

  .notification-polygon {
    background-color: white;
    z-index: 2;
    width: 63px;
  }

  .badge-notification {
    background-color: map-get($map: $status-colors, $key: 'danger');
    width: 29px;
    height: 20px;
    position: absolute;
    z-index: 99;
    top: -5px;
    right: -2px;
    text-align: center;

    span {
      font-size: 12px; //map-get($map: $typography-size, $key: 'sm');
      color: white;
    }
  }
}
