@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
      format("woff2");
  }
  
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  
  /* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
   * The idea is that it is easy to extend/override builtin styles with very little effort.
   */
  
  .mdl-demo .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
  }
  
  .mdl-demo .toggle {
    font: normal normal normal 18px/1 "Material Icons";
    color: #555;
    white-space: pre;
    margin-right: 4px;
  }
  
  .mdl-demo .toggle.collapsed::after {
    cursor: pointer;
    content: "\E145";
    vertical-align: middle;
    border: 1px solid #555;
    border-radius: 2px;
  }
  
  .mdl-demo .toggle.expanded::after {
    cursor: pointer;
    content: "\E15b";
    vertical-align: middle;
    border: 1px solid #555;
    border-radius: 2px;
    max-width: 10px;
    max-height: 2px;
  }
  
  /* checkbox styles */
  .mdl-demo .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;
    
  }
  
  .mdl-demo .checkbox-item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 1px solid #5D5F60;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  .mdl-demo .checkbox-item:checked:before {
    background-color: #36A4FF;
    
  }
  
  .mdl-demo .tag-item {
    display: inline-block;
    border-radius: 7px;
    border: #5D5F60 1px solid;
    color: #5D5F60;
    font-size: 0.8rem;
    font-size: 20px;
    justify-content: center;
    vertical-align: middle;
    margin-top: 0.6%;
    margin-right: 0.5%;
    height: 40px;

  
    .tag{
      margin-left: 10px;
    }
  
  
  
  
  
  }
  
    
  
  
  
  .tag-remove {
    background: transparent;
    border: none;
    font-size: 25px;
    color: #5D5F60;
    cursor: pointer;
  
    
   
  
    
    
  }
  .mdl-demo .infinite-scroll-component  {
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 0px 0px 5px 5px;
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    padding: 4%;
    overflow: scroll;
    margin-left: -2.35%;
    max-height: 300px;
    margin-top: -2.5px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-width: 1px;
    
  
  }
  
  
    
  .search{
  
    z-index: 1;
    position: relative;
    border-radius: 7px;
    background: transparent;
    border: none;
    left:0;
    width: 100%;
    height: 100%;
  
  
  
  }