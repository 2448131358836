@import '../../../styles/PoultryWeights/_partial.scss';

.modalNotification {
  // .modal-notifications-content {
  //   // min-width: 1000px;
  //   min-width: 60vw;
  // }

  .notification-modal-body {
    min-height: 60vh;
    //max-height: 60vh;
    overflow: auto;
  }
  p {
    font-size: 17px;
  }
  .notificationBell {
    width: 27px;
    margin-top: 8px;
  }
  .badge-notification {
    background-color: $secondary-color;
  }
  .modal-title {
    width: 100%;
  }
  .MuiTableHead-root th {
    background-color: $default-dark-gray;
    color: $white !important;
  }
  .input-group {
    img {
      height: 27px;
    }
    input {
      height: 43px;
      border: 1px solid $default-dark-gray;
      border-left: none;
    }
    .input-group-text {
      background-color: $white;
      border: 1px solid $default-dark-gray;
    }
  }
  .imgNotificationList {
    width: 19px;
    margin-right: 10px;
    height: auto;
  }
  .lightGrayBG {
    background-color: $default-light-gray;
  }
  .txtNotificationHeader {
    // font-size: map-get($map: $typography-size, $key: 'lg');
    font-size: 20px;
  }
  .txtNotificationMessage {
    font-size: map-get($map: $typography-size, $key: 'sm');
  }
  .txtNotificationDate {
    // font-size: map-get($map: $typography-size, $key: 'sm');
    font-size: 12px !important;
    color: $default-dark-gray;
  }
  .txtNotificationSensor {
    float: right;
    font-size: 12px;  
    margin-right: 4em;
    color: $default-dark-gray;

  }
 
}
