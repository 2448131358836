@import '../../../styles/Bees/partial';

.app-bar {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
  background-color: $primary-color;
  height: 45px;
  justify-content: space-between;

  div {
    display: flex;
    gap: 5px;
  }
}

.badgeNotification {
  position: absolute;
  color: white;
  background-color: red;
  border-radius: 15px;
  margin-left: -15px;
  width: 25px;
  height: 25px;
}

.badgeNotificationCount {
  vertical-align: middle;
  margin-left: -0.2em;
}
