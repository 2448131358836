@import '../../styles/Bees/partial';
@media only screen and (max-width: 600px) {
.bottom-tabs-container {
  display: flex;
  width: 100%;
  height: fit-content;
  background: $tertiary-color;
  overflow: hidden;
}
.nav-item {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.sidebarText {
  color: white;
  font-size: 12px;
}
}
@media only screen and (max-width: 600px) {
  .bottom-tabs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    overflow-x:scroll;
    &::-webkit-scrollbar {
      height: 9px;
    }
    width: 100%;
    .sidebarIcon {
      width: 20px;
    }
    .image-container {
      height: 30px;
      margin-bottom: 10px;
      z-index: 99;
    }
  }
}
