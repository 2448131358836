//app colors
$primary-color: #113252;
$secondary-color: #36A4FF;
$tertiary-color: #417EBA;

$theme-colors: (
  'primary': $primary-color
);
$default-label-color: #36A4FF;
$white: #ffffff;
$black: #000000;
$red: #FF6363;

//drawer color

$status-colors: (
  'danger': #ff5852,
  'ok': #00ff88,
  'warning': $secondary-color,
);
