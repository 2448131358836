@import './styles/PoultryWeights/_partial.scss';
@import '../node_modules/bootstrap/scss/bootstrap';

.card {
  border-radius: 10px !important;
}
.mainBackground {
  
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent !important;
  
}
.logo {
  width: 230px;
  
  padding-left: 60px;
}
.heading {
  color: $primary-color;
  margin-bottom: 20px;
  
}
.form-control {
  border: 1px solid $default-dark-gray;
  border-radius: 5px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $default-dark-gray;
  opacity: 0.5; /* Firefox */
  font-weight: $default-bold-text;
}
a {
  color: $primary-color;
  text-decoration: none;
}

.dialog-error-span {
  color: red;
  padding: 10px;
}
.modal-add-button {
  width: 30%;
}
.customModal .modal-header {
  border-bottom: 0px !important;
}
.customModal .modal-footer {
  border-top: 0px !important;
}
.customModal .modal-content {
  border-radius: $default-border-radius;
}

.customModal select {
  width: 30%;
  border-radius: $default-border-radius;
}
.customModal .txtModalLabels {
  color: $default-label-color;
}
.customModal .form-control::placeholder {
  color: $default-dark-gray;
}

.transparent {
  background-color: transparent;
  border: none;
}

.action-edit-image {
  width: 20px;
  cursor: pointer;
}
.red {
  color: $red !important;
}
.green {
  color: $green !important;
}
.primary-color {
  color: $primary-color !important;
}
.bold {
  font-weight: $default-bold-text;
}
.addMaterialEntryDate .rmdp-input {
  height: 40px !important;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $default-dark-gray $white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: $white;
}

*::-webkit-scrollbar-thumb {
  background-color: $default-dark-gray;
  border-radius: 20px;
  border: 3px solid $white;
}
@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
