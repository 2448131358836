.app-bar-logo-container {
  position: absolute;
  background: url('../../../../assets/images/appBar/BeesAppbar/polygon.svg');
  width: 250px;
  height: 220px;
  transform: scale(0.8);
  top: -120px;
  left: -75px;

  .inner-image {
    position: absolute;
    top: 130px;
    right: 70px;
    transform: scale(1.2);
  }
  .logo-polygon {
    width: 150px;
    height: 150px;
  }
}
