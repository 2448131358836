//app colors
$primary-color: #7b7b7b;
$secondary-color: #ffd500;
$tertiary-color: #3b3b3b;

$theme-colors: (
  'primary': $primary-color
);
$default-label-color: #2d3a8e;
$white: #ffffff;
$black: #000000;
$red: #FF6363;

//drawer color

$status-colors: (
  'danger': #ff5852,
  'ok': #00ff88,
  'warning': $secondary-color,
);
